import React from 'react';

import ImageWithPattern from '../../components/ImageWithPattern/ImageWithPattern';
import TitleUnderlineParagraph from '../../components/TitleUnderlineParagraph/TitleUnderlineParagraph';
import { cn } from '../../utils';
import * as styles from './TextAndImage.module.scss';
import ITextAndImage from './TextAndImage.type';

const TextAndImage = (props: ITextAndImage) => 
        <section className={styles.section}>
            <div className={cn("container", styles.container)}>
                <div className={cn(styles.column, styles.leftColumn)}>
                    <ImageWithPattern
                        {...props.image}
                        className={styles.image}
                    />
                </div>
                <div className={cn(styles.column, styles.rightColumn)}>
                    <TitleUnderlineParagraph
                        heading={props.title}
                        headingLevel="h2"
                        text={props.text}
                        underline="left"
                    />
                </div>
            </div>
        </section>

export default TextAndImage;
